var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white hp-bg-dark-100 border border-black-60 hp-border-color-dark-80 rounded overflow-hidden h-100"},[_c('b-nav',{staticClass:"h-100 align-items-center hp-overflow-x-auto flex-nowrap",attrs:{"pills":""}},[_c('b-nav-item',{staticClass:"h-100",attrs:{"link-classes":`d-block rounded-0 py-12 px-24 h-100 d-flex align-items-center  ${
        this.propsTabIndex.index == 0 ? 'active' : 'text-black-100 hp-text-color-dark-0'
      }`},on:{"click":function($event){return _vm.tabMenu(0)}}},[_vm._v(" All ")]),_c('b-nav-item',{staticClass:"h-100 border-left border-black-60 hp-border-color-dark-80",attrs:{"link-classes":`d-block rounded-0 py-12 px-24 h-100 d-flex align-items-center  ${
        this.propsTabIndex.index == 1 ? 'active' : 'text-black-100 hp-text-color-dark-0'
      }`},on:{"click":function($event){return _vm.tabMenu(1)}}},[_vm._v(" Light ")]),_c('b-nav-item',{staticClass:"h-100 border-left border-black-60 hp-border-color-dark-80",attrs:{"link-classes":`d-block rounded-0 py-12 px-24 h-100 d-flex align-items-center  ${
        this.propsTabIndex.index == 2 ? 'active' : 'text-black-100 hp-text-color-dark-0'
      }`},on:{"click":function($event){return _vm.tabMenu(2)}}},[_vm._v(" Curved ")]),_c('b-nav-item',{staticClass:"h-100 border-left border-black-60 hp-border-color-dark-80",attrs:{"link-classes":`d-block rounded-0 py-12 px-24 h-100 d-flex align-items-center  ${
        this.propsTabIndex.index == 3 ? 'active' : 'text-black-100 hp-text-color-dark-0'
      }`},on:{"click":function($event){return _vm.tabMenu(3)}}},[_vm._v(" Broken ")]),_c('b-nav-item',{staticClass:"h-100 border-left border-black-60 hp-border-color-dark-80",attrs:{"link-classes":`d-block rounded-0 py-12 px-24 h-100 d-flex align-items-center  ${
        this.propsTabIndex.index == 4 ? 'active' : 'text-black-100 hp-text-color-dark-0'
      }`},on:{"click":function($event){return _vm.tabMenu(4)}}},[_vm._v(" Bold ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }