var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'General',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" We used Iconly Icon for Yoda Admin Template ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{staticClass:"hp-flex-none w-auto mb-32"},[_c('tab-menu',{attrs:{"propsTabIndex":{ index: _vm.index }},on:{"tabMenuChange":_vm.tabMenu}})],1),_c('b-col',{staticClass:"hp-icon-search-col mb-32"},[_c('search',{on:{"searchChange":_vm.search}})],1)],1),_c('tab-content',{attrs:{"propsSearch":{ searchVal: _vm.searchVal },"propsTabIndex":{ index: _vm.index }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }